/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import React from "react";
import { Popover, Transition } from "@headlessui/react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import {
  MenuIcon,
  BriefcaseIcon,
  CashIcon,
  PhoneIcon,
  CollectionIcon,
  LocationMarkerIcon,
  OfficeBuildingIcon,
  CloudUploadIcon,
  GlobeIcon,
  XIcon,
} from "@heroicons/react/outline";

import { ChevronDownIcon } from "@heroicons/react/solid";
import { StaticImage } from "gatsby-plugin-image";

const solutions = [
  {
    name: "White Label Bookkeeping",
    description: "Partner with our team to empower your bookkeeping abilities.",
    href: "/white-label-bookkeeping",
    icon: BriefcaseIcon,
  },
];

const solutions1 = [
  {
    name: "Bookkeeping Services",
    description:
      "We provide professional bookkeeping services for companies of all sizes.",
    href: "/bookkeeping-services-toronto",
    icon: CashIcon,
  },
  {
    name: "Freelance Bookkeeping",
    description: "Freelance your financial workflows to our experienced team of bookkeepers.",
    href: "/freelance-bookkeeping-toronto",
    icon: CollectionIcon,
  },
  {
    name: "Outsourced Bookkeeping",
    description:
      "Outsource your bookkeeping to our team of professional bookkeepers.",
    href: "/outsource-bookkeeping",
    icon: CloudUploadIcon,
  },
];


const solutions3 = [
  {
    name: "Bookkeeping Services",
    description:
      "We provide professional bookkeeping services for companies of all sizes.",
    href: "/bookkeeping-services-toronto",
    icon: CashIcon,
  },
  {
    name: "Outsourced Bookkeeping",
    description:
      "Outsource your bookkeeping to our team of professional bookkeepers.",
    href: "/outsource-bookkeeping",
    icon: CloudUploadIcon,
  },
  {
    name: "White Label Bookkeeping",
    description: "Partner with our team to empower your bookkeeping abilities.",
    href: "/white-label-bookkeeping",
    icon: BriefcaseIcon,
  },
  {
    name: "Freelance Bookkeeping",
    description: "Freelance your financial workflows to our experienced team of bookkeepers.",
    href: "/freelance-bookkeeping-toronto",
    icon: CollectionIcon,
  },
];




const locations = [
  {
    name: "Toronto",
    description:
      "Reach out to our Toronto based team for bookkeeping services.",
    href: "/",
    icon: OfficeBuildingIcon,
  },
  {
    name: "Vancouver",
    description:
      "We offer bookkeeping services throughout British Columbia including Vancouver.",
    href: "/bookkeeping-services-vancouver",
    icon: GlobeIcon,
  },
  {
    name: "Mississauga",
    description:
      "Connect with our team in Mississauga for bookkeeping services.",
    href: "/bookkeeping-mississauga",
    icon: LocationMarkerIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  return (
    <header className="sticky top-0 z-50">
      
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="../../images/apple-touch-icon.png"
      />
     
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="../../images/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="../../images/favicon-16x16.png"
      />

      <Helmet>
       
        <script type="application/ld+json">
          {`
                  {
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "url": "https://www.certusbookkeeping.com/",
                    "logo": "https://storage.googleapis.com/archatech/CertusBookkeeping/LogoCB.png"
                  }
                `}
        </script>
      </Helmet>
      <Popover className="relative bg-white">
        <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
          <div>
            <Link href="/" to="/">
              <StaticImage
                src="../../images/CLogo1.png"
                alt="A dinosaur"
                 className="logo"
                style={{
                  // You can set a maximum height for the image, if you wish.
                  maxHeight: 120,
                  maxWidth: 120,
                }}
                border="5px"
                // You can optionally force an aspect ratio for the generated image

                // This is a presentational image, so the alt should be an empty string
                // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
            <Popover.Group as="nav" className="flex space-x-10">
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
                      )}
                    >
                      <span>Bookkeeping Services</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-600" : "text-gray-400",
                          "ml-2 h-5 w-5 group-hover:text-gray-500"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-3xl">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                            {solutions1.map((item) => (
                              <Link
                                to={item.href}
                                key={item.name}
                                href={item.href}
                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                              >
                                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-secondary text-white sm:h-12 sm:w-12">
                                  <item.icon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">
                                    {item.name}
                                  </p>
                                  <p className="mt-1 text-sm text-gray-500">
                                    {item.description}
                                  </p>
                                </div>
                              </Link>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              {solutions.map((item) => (
                <Link
                  href={item.href}
                  to={item.href}
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  {item.name}
                </Link>
              ))}
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
                      )}
                    >
                      <span>Locations</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-600" : "text-gray-400",
                          "ml-2 h-5 w-5 group-hover:text-gray-500"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-3xl">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                            {locations.map((item) => (
                              <Link
                                to={item.href}
                                key={item.name}
                                href={item.href}
                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                              >
                                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-secondary text-white sm:h-12 sm:w-12">
                                  <item.icon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">
                                    {item.name}
                                  </p>
                                  <p className="mt-1 text-sm text-gray-500">
                                    {item.description}
                                  </p>
                                </div>
                              </Link>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>
            <div className="flex items-center md:ml-12">
              <Link
                href="#"
                to="/about"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                About Us
              </Link>
              <a
                href="tel:1-833-204-5460"
                className="phone-call ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-secondary hover:bg-theme"
              >
                <PhoneIcon height="25" className="mr-2" />
                1-(833)-204-5460
              </a>
              <Link
                to="/contact-us"
                href="/contact-us"
                className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-secondary hover:bg-theme"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg  bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <Link href="/" to="/" className="link flex-center">
                      <StaticImage
                        src="../../images/CLogo1.png"
                        alt="A dinosaur"
                        className="logo2"
                        style={{
                          // You can set a maximum height for the image, if you wish.
                          maxHeight: 120,
                         
                          maxWidth: 120,
                        }}
                        border="5px"
                        // You can optionally force an aspect ratio for the generated image

                        // This is a presentational image, so the alt should be an empty string
                        // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
                      />
                    </Link>
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                
                <div className="mt-6">
                  <nav className="grid gap-6">
                    {solutions3.map((item) => (
                      <Link
                        to={item.href}
                        key={item.name}
                        href={item.href}
                        className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                      >
                        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-secondary text-white">
                          <item.icon className="h-6 w-6" aria-hidden="true" />
                        </div>
                        <div className="ml-4 text-base font-medium text-gray-900">
                          {item.name}
                        </div>
                      </Link>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5">
                <div className="grid grid-cols-2 gap-4">
                  {locations.map((item) => (
                    <Link
                      to={item.href}
                      key={item.name}
                      href={item.href}
                      className="text-base font-medium text-gray-900 hover:text-gray-700"
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
                <div className="mt-6">
                  <a
                    href="tel:1-833-204-5460"
                    className="phone-call w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-secondary hover:bg-theme"
                  >
                    <PhoneIcon height="25" className="mr-2" />
                    1-(833)-204-5460
                  </a>
                </div>
                <div className="mt-6">
                  <Link
                    to="/contact-us"
                    href="/contact-us"
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-secondary hover:bg-theme"
                  >
                    Contact
                  </Link>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  );
}
